<template>
    <div class="grey-container is-flex is-justify-content-space-between is-align-items-center mb-6"
             :class="{'is-sticky':weekSliderIsSticky}">
        <span class="chevron chevron-left" :class="{'is-active': isChevronLeftActive}" @click="prevWeek">
            <img src="@/assets/images/week_chevron_left.svg" alt="previous week">
        </span>

        <p><span class="week-number">KW {{ weekNumber }}</span> / {{ startWeekDay }} - {{ endWeekDay }}</p>

        <span class="chevron chevron-right" :class="{'is-active': isChevronRightActive}" @click="nextWeek">
            <img src="@/assets/images/week_chevron_right.svg" alt="next week">
        </span>
    </div>
</template>

<script>
import {
    getISOWeek,
    startOfISOWeek,
    endOfISOWeek,
    format,
    add,
    sub,
    startOfMonth,
    getYear,
    getMonth,
    getISOWeekYear
} from 'date-fns';

export default {
    name: 'WeekSlider',
    data() {
        return {
            date: new Date(),
            weekNumber: 0,
            yearWeek: '',
            startWeekDay: '',
            endWeekDay: '',
            lastPastValidWeek: 0,
            lastFutureValidWeek: 0,
            weekSliderIsSticky: false,
            isChevronRightActive: true,
            isChevronLeftActive: true
        }
    },
    methods: {
        updateWeek(date) {
            this.weekNumber = getISOWeek(date)
            this.yearWeek = getYear(date) + (getISOWeek(date) < 10 ? '0' : '') + (getMonth(date) === 0 && getISOWeek(date) > 50 ? '00' : getISOWeek(date))
            this.startWeekDay = format(startOfISOWeek(date), 'dd.MM.')
            this.endWeekDay = format(endOfISOWeek(date), 'dd.MM.yyyy')

            this.isChevronLeftActive = this.yearWeek > this.lastPastValidWeek
            this.isChevronRightActive = this.yearWeek < this.lastFutureValidWeek

            const week = {
                from: format(startOfISOWeek(date), 'yyyy-MM-dd'),
                to: format(endOfISOWeek(date), 'yyyy-MM-dd')
            }
            const weekNumber = getISOWeek(date)

            this.$store.commit('SET_SELECTED_WEEK', week)
            this.$store.commit('SET_WEEK_NUMBER', weekNumber)
            this.$parent.getWeekOrder()
        },
        nextWeek() {
            if (!this.isChevronRightActive) {
                return
            }
            this.date = add(this.date, {weeks: 1})
            this.updateWeek(this.date)
        },
        prevWeek() {
            if (!this.isChevronLeftActive) {
                return
            }
            this.date = sub(this.date, {weeks: 1})
            this.updateWeek(this.date)
        },
        setWeekLimit(date) {
          this.lastFutureValidWeek = 
              getISOWeekYear(add(date, { weeks: Number(process.env.VUE_APP_FUTURE_WEEKS) })) +
              (getISOWeek(add(date, { weeks: Number(process.env.VUE_APP_FUTURE_WEEKS) })) < 10 ? "0" : "") +
              getISOWeek(add(date, { weeks: Number(process.env.VUE_APP_FUTURE_WEEKS) }));
          this.lastPastValidWeek = getYear(sub(startOfMonth(date), {months: 1})) + ((getISOWeek(sub(startOfMonth(date), {months: 1})) < 10 ? '0' : '') + getISOWeek(sub(startOfMonth(date), {months: 1})) > 50 && getMonth(sub(startOfMonth(date), {months: 1})) === 0 ? '00' : (getISOWeek(sub(startOfMonth(date), {months: 1})) < 10 ? '0' : '') + getISOWeek(sub(startOfMonth(date), {months: 1})))
        },
        getScroll() {
            let offsetTop = document.querySelector('.grey-container').getBoundingClientRect().top
            if (offsetTop < 0) {return}
            this.weekSliderIsSticky = offsetTop === 0;
        }
    },
    mounted() {
        this.setWeekLimit(this.date)
        this.updateWeek(this.date)
        window.addEventListener('scroll', this.getScroll)
    },
    unmounted() {
        window.removeEventListener('scroll', this.getScroll)
    }
}
</script>

<style lang="scss" scoped>
.grey-container {
    position: sticky;
    top: 0;
    transform: none;
    width: inherit;
    transition: $transition-standard;
    margin: 0 $gap;
    z-index: 12;
    -webkit-overflow-scrolling: auto;
    -webkit-transform: translate3d(0,0,0);

    &.is-sticky {
        transition: $transition-standard;
        border-radius: 0;
        margin: 0;
        box-shadow: 0 0 $shadow-blur $shadow-color;
    }

    p {
        font-size: $font-size-small;

        @media all and (min-width: $mobile-xs) {
            font-size: 1rem;
        }
    }
}

.chevron {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: not-allowed;
    opacity: .25;

    &.is-active {
        cursor: pointer;
        opacity: 1;
    }
}

.week-number {
    font-weight: $font-weight-bold;
}
</style>
